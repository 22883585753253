<template>
  <div class="home">

    <div style="width: 60%; margin: auto;">
      <el-row style="text-align: center;">
        <span >航班列表</span>
      </el-row>
      <el-row>
        <el-button size="small" @click="accountDialogVisible = true" type="primary">添加航班</el-button>
        <el-button size="small" @click="$router.push('/')" type="primary">去账号页面</el-button>
      </el-row>
      <el-table
      :data="flightList"
      style="width: 100%">
      <el-table-column
        prop="departure"
        label="出发地"
        >
      </el-table-column>
      <el-table-column
        prop="departure_code"
        label="出发地编码">
      </el-table-column>
      <el-table-column
        prop="destination"
        label="到达地">
      </el-table-column>
      <el-table-column
        prop="destination_code"
        label="到达地编码">
      </el-table-column>
      <el-table-column
        prop="flag"
        label="航班编码">
      </el-table-column>
      <el-table-column
        prop="departure_time"
        label="出发时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index)"
            type="text"
            size="small">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog :visible.sync="accountDialogVisible" title="添加航班" width="50%">
      <el-form ref="flightForm" :model="flightForm" label-width="120px">
        <el-form-item label="出发地">
          <el-input @change="searchCode(1)" v-model="flightForm.departure" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="出发地编码">
          <el-select v-model="flightForm.departure_code" placeholder="请选择">
            <el-option
              v-for="item in departureCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到达地">
          <el-input @change="searchCode(2)" v-model="flightForm.destination" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="到达地编码">
          <el-select v-model="flightForm.destination_code" placeholder="请选择">
            <el-option
              v-for="item in destinationCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出发日期">
          <el-date-picker
            v-model="flightForm.departure_time"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="航班编码">
          <el-input v-model="flightForm.flag" style="width: 70%;" placeholder="请填写航班编码,最好是填最小的那个,只需要填数字，比如 1 "></el-input>
        </el-form-item> -->
        <!-- <div v-for="(item , index) in fuwuqiList" :key="index">
          <span>航班编码 {{ item.flag }} : {{ item.count }}个</span><br/>
        </div> -->
        <el-form-item>
          <el-button type="primary" @click="sureAccount">确定</el-button>
          <el-button @click="accountDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="editAccountDialogVisible" title="编辑账号" width="50%">
      <el-form ref="editAccountData" :model="editAccountData" label-width="80px">
        <el-form-item label="服务器号">
          <el-input v-model="editAccountData.flag" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="通知邮箱">
            <el-input v-model="editAccountData.email" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sureEditAccount">确定</el-button>
          <el-button @click="editAccountDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
// @ is an alias to /src
import http from '../utils/http'
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      flightList: [],
      accountDialogVisible: false,
      editAccountDialogVisible: false,
      flightForm: {
        departure: '',
        departure_code: '',
        destination: '',
        destination_code: '',
        departure_time: '',
        flag: ''
      },
      editAccountData: {},
      departureCodeList: [],
      destinationCodeList: [],
      passerList: []
      // fuwuqiList: []
    }
  },
  mounted () {
    this.getAllFlight()
    // this.queryFuwuqi()
  },
  methods: {
    searchCode (type) {
      let code = ''
      if (type === 1) {
        code = this.flightForm.departure
      } else {
        code = this.flightForm.destination
      }
      http.post('/searchCode', {
        city: code
      }, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        const Datas = res.Datas
        if (type === 1) {
          this.departureCodeList = []
          for (let index = 0; index < Datas.length; index++) {
            const element = Datas[index]
            const obj = { label: element.Name, value: element.Code }
            this.departureCodeList.push(obj)
          }
        } else {
          this.destinationCodeList = []
          for (let index = 0; index < Datas.length; index++) {
            const element = Datas[index]
            const obj = { label: element.Name, value: element.Code }
            this.destinationCodeList.push(obj)
          }
        }
      })
    },
    sureAccount () {
      if (this.flightForm.departure === '') {
        this.$message({
          message: '出发地不能为空',
          type: 'error'
        })
        return
      }
      if (this.flightForm.departure_code === '') {
        this.$message({
          message: '出发地编码不能为空',
          type: 'error'
        })
        return
      }
      if (this.flightForm.destination === '') {
        this.$message({
          message: '到达地不能为空',
          type: 'error'
        })
        return
      }
      if (this.flightForm.destination_code === '') {
        this.$message({
          message: '到达地编码不能为空',
          type: 'error'
        })
        return
      }
      if (this.flightForm.departure_time === '') {
        this.$message({
          message: '出发日期不能为空',
          type: 'error'
        })
        return
      }
      // if (this.flightForm.flag === '') {
      //   this.$message({
      //     message: '服务器编码不能为空',
      //     type: 'error'
      //   })
      //   return
      // }
      http.post('/addFlight', this.flightForm, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.$message(res.msg)
          this.accountDialogVisible = false
          this.getAccoutList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    getAllFlight () {
      http.post('/getAllFlight', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.flightList = res.data
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    // queryFuwuqi () {
    //   http.post('/getFwq', {}, function (error) {
    //     console.info(error)
    //   }, localStorage.token).then(res => {
    //     if (res.status === 0) {
    //       this.fuwuqiList = []
    //       for (let index = 0; index < res.datas.length; index++) {
    //         const element = res.datas[index]
    //         var obj = { label:  }
    //         debugger
    //       }

    //       this.fuwuqiList = res.datas
    //     } else {
    //       this.$message({
    //         message: res.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },
    editAccount (a) {
      this.editAccountDialogVisible = true
      this.editAccountData = this.flightList[a]
      http.post('/getAllPassage', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.passerList = res.data
          res.data.forEach(element => {
            if (element.accountId === this.editAccountData.id) {
              this.psCheckList.push(element.id)
            }
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    sureEditAccount () {
      if (this.editAccountData.flag === '') {
        this.$message({
          message: '服务器号不能为空',
          type: 'error'
        })
        return
      }
      if (this.editAccountData.email === '') {
        this.$message({
          message: '通知邮箱不能为空',
          type: 'error'
        })
        return
      }
      const obj = {
        flag: this.editAccountData.flag,
        email: this.editAccountData.email,
        passages: this.psCheckList,
        accountId: this.editAccountData.id
      }
      http.postJson('/editAccount', obj, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.passerList = res.data
          res.data.forEach(element => {
            if (element.accountId === this.editAccountData.id) {
              this.psCheckList.push(element.id)
            }
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    deleteRow (a) {
      const obj = {
        id: this.flightList[a].id,
        flag: this.flightList[a].flag
      }
      http.post('/deleteFlight', obj, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.$message(res.msg)
          this.getAllFlight()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
